import React, { useState } from "react"
import { Link } from "gatsby"
import ScrollLock, { TouchScrollable } from "react-scrolllock"
import Fade from "react-reveal/Fade"

const Pricing = data => {
  const [showModal, setShowModal] = useState(false)

  // We're using just one modal for the whole thing
  const [doorData, setDoorData] = useState({
    pricing_data: "Pricing Data",
    title: "Door Title",
    uid: "",
  })

  // Make the modal switch data depending on what you've clicked
  function handleClick(title, data, uid) {
    setDoorData({
      pricing_data: data,
      title: title,
      uid: uid,
    })
    setShowModal(true)
  }

  const pricingItems = data.edges.map((item, index) => {
    return (
      <div className="lg:w-1/2 w-full py-2 lg:px-2" key={index}>
        <Fade distance="20px" bottom>
          <div
            className="bg-white w-full relative lg:p-8 p-0 lg:pt-8 pt-5 lg:pl-0 pl-0 flex items-center justify-between group cursor-pointer outline-none focus:outline-none"
            role="button"
            tabIndex={0}
            onClick={() => {
              handleClick(
                item.node.data.title.text,
                item.node.data.pricing,
                item.node.uid
              )
            }}
            onKeyDown={() => {
              handleClick(
                item.node.data.title.text,
                item.node.data.pricing,
                item.node.uid
              )
            }}
          >
            <div className="text-left text-sm flex items-center">
              <img
                src={`${item.node.data.pricing_image.url}&w=120`}
                alt={item.node.data.pricing_image.alt}
                className="mr-6"
              />
              <div>
                <h3 className="text-darkgrey font-bold tracking-widest uppercase group-hover:text-gold transition duration-300 ease-in-out">
                  {item.node.data.title.text}
                </h3>
                {item.node.data.pricing[0] ? (
                  <span>
                    <em>from </em>
                    <span className="text-gold ml-1 tracking-widest font-bold">
                      £{item.node.data.pricing[0].price}
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`plus accordion absolute right-6 top-1/2 transform -translate-y-1/2`}
            ></div>
            {/* <i className="fal fa-plus text-xl group-hover:text-gold transition duration-300 ease-in-out"></i> */}
          </div>
        </Fade>
      </div>
    )
  })

  return (
    <React.Fragment>
      <section className="bg-offwhite">
        <div className="container text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold rfs:text-6xl capitalize">
              {data.type} Pricing
            </h2>
            <div className="w-12 mt-5 separator"></div>
            <p className="max-w-2xl m-auto mt-12">
              These prices are just a guide, {data.type === "internal door" && "based on our average order qty of 12,"} for more information please call us for a full quote on <a href="tel:0800
              138 6688">0800
              138 6688</a> or <Link to="/request-a-quote/">request a quote</Link>.
              Fitting / Installation available at an additional cost, please
              call for details.
            </p>
            <p className="mt-6 max-w-xl m-auto italic font-bold text-sm">
              *Prices correct as of January 2021. Deuren reserves the right to
              amend these prices at any time.
            </p>
          </Fade>
          <div className="flex flex-wrap justify-center mt-12">
            {pricingItems}
          </div>
        </div>
      </section>
      {/* The modal */}
      <ScrollLock isActive={showModal} />
      <TouchScrollable>
        <div
          className={`justify-center items-center flex overflow-x-hidden overflow-y-scroll fixed inset-0 ${showModal ? 'z-[9999999999]' : 'z-50'} outline-none focus:outline-none transition duration-500 ease-in-out ${
            showModal ? "visible" : "invisible"
          }`}
        >
          <div
            className={`relative w-auto lg:h-auto h-full lg:my-6 mx-auto max-w-6xl z-60 md:p-8`}
          >
            <div
              className={`shadow-lg md:p-16 p-8 relative flex flex-col w-full min-h-full bg-white outline-none focus:outline-none z-50 transition-opacity duration-500 ease-in-out ${
                showModal ? "opacity-100" : "opacity-0"
              } `}
            >
              <div className="relative">
                <h3 className="font-display text-gold rfs:text-5xl">
                  {doorData.title}
                </h3>
                <div className="w-12 mt-5 separator"></div>
                <p className="max-w-2xl m-auto mt-12">
                  These prices are just a guide, for more information please call us for a full quote on <a href="tel:0800 138 6688">0800 138 6688</a> or <Link to="/request-a-quote/">request a quote</Link>. Fitting /
                  Installation available at an additional cost, please call for
                  details.
                </p>
                <button
                  className="absolute -top-6 p-6 -right-6 outline-none focus:outline-none hover:text-gold"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fal fa-times"></i>
                </button>
                {showModal ? (
                  <div className="my-8">
                    {doorData.pricing_data.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`flex justify-between items-center md:text-xs text-xxs border-b py-3 border-grey ${
                            index === 0 ? "border-t" : ""
                          }`}
                        >
                          <h3 className="font-bold tracking-widest uppercase w-10/12">
                            {item.title.text}
                          </h3>
                          <span>
                            <em>from</em>{" "}
                            <span className="w-2/12 tracking-widest inline-block font-bold">
                              £{item.price}
                            </span>
                          </span>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  ""
                )}
                <Link
                  to={`/${data.type.replace(" ", "-").toLowerCase()}s/styles/${
                    doorData.uid
                  }`}
                  className="btn md:mr-3 md:mb-0 mb-2 md:inline block md:text-left text-center"
                >
                  View Door style
                </Link>
                <Link
                  to="/request-a-quote/"
                  className="btn md:inline block md:text-left text-center"
                >
                  Request a quote
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`fixed inset-0 z-40 bg-darkgrey transition-opacity duration-500 focus:outline-none outline-none ease-in-out ${
              showModal ? "opacity-75" : "opacity-0"
            }`}
            role="button"
            tabIndex={0}
            onClick={() => setShowModal(false)}
            onKeyDown={() => setShowModal(false)}
          >
            <span className="invisible">Close modal</span>
          </div>
        </div>
      </TouchScrollable>
    </React.Fragment>
  )
}

export default Pricing
