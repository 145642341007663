import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import CrossfadeImage from "react-crossfade-image"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "src/utils/GatsbyLink"

const AccordionItem = props => {
  const handleClick = item => {
    let newData = props.arr.map(data => {
      data.show = item.title === data.title
      return data
    })
    props.setItem(newData, item.image, item.alt)
  }

  return (
    <Fade distance="20px" bottom>
      <div className="border-b border-gold last:border-b-0">
        <div
          className="w-full relative py-3 cursor-pointer focus:outline-none"
          role="button"
          tabIndex={0}
          onClick={() => handleClick(props.data)}
          onKeyDown={() => handleClick(props.data)}
        >
          <h2 className="font-display text-gold rfs:text-3xl pr-12">
            {props.data.title}
          </h2>
          <div
            className={`accordion absolute w-4 h-4 right-0 top-1/2 transform -translate-y-1/2 ${
              props.data.show ? "active" : ""
            }`}
          >
            <div
              className={`w-full h-px bg-gold absolute top-1/2 transform transition-all duration-500 ${
                props.data.show ? "rotate-180" : "rotate-0"
              }`}
            ></div>
            <div
              className={`w-full h-px bg-gold absolute top-1/2 transform transition-all duration-500 ${
                props.data.show ? "rotate-0" : "-rotate-90"
              }`}
            ></div>
          </div>
        </div>
        <div
          className={`w-full lg:pr-20 overflow-hidden transition-all duration-1000 ease-in-out ${
            props.data.show ? "xl:max-h-28 lg:max-h-36 max-h-screen" : "max-h-0"
          }`}
        >
          <div className="pb-3 text-sm">
            <RichText
              render={props.data.description}
              serializeHyperlink={GatsbyLink}
            />
            <Img
              fluid={props.data.image_fluid}
              alt={props.data.title}
              className="lg:hidden my-6"
            />
          </div>
        </div>
      </div>
    </Fade>
  )
}

const ProductAccordion = props => {
  const query = useStaticQuery(graphql`
    query AccordionQuery {
      InternalAccordion: prismicAccordion(
        uid: { eq: "technically-superior-block" }
      ) {
        data {
          title {
            text
          }
          description {
            richText
          }
          button_link {
            text
          }
          accordion {
            accordion_title {
              text
            }
            accordion_description {
              richText
            }
            featured_image {
              url
              alt
              fluid(maxWidth: 500) {
                src
              }
            }
          }
        }
      }
      FrontAccordion: prismicAccordion(
        uid: { eq: "technically-superior-front" }
      ) {
        data {
          title {
            text
          }
          description {
            richText
          }
          button_link {
            text
          }
          accordion {
            accordion_title {
              text
            }
            accordion_description {
              richText
            }
            featured_image {
              url
              alt
              fluid(maxWidth: 500) {
                src
              }
            }
          }
        }
      }
      GarageAccordion: prismicAccordion(
        uid: { eq: "technically-superior-garage" }
      ) {
        data {
          title {
            text
          }
          description {
            richText
          }
          button_link {
            text
          }
          accordion {
            accordion_title {
              text
            }
            accordion_description {
              richText
            }
            featured_image {
              url
              alt
              fluid(maxWidth: 500) {
                src
              }
            }
          }
        }
      }
    }
  `)

  let data = {}

  switch (props.type) {
    case "internal-doors":
      data = query.InternalAccordion.data
      break
    case "front-doors":
      data = query.FrontAccordion.data
      break
    case "garage-doors":
      data = query.GarageAccordion.data
      break
    default:
      data = query.InternalAccordion.data
      break
  }

  const accordionData = data.accordion.map((item, index) => {
    return {
      title: item.accordion_title.text,
      description: item.accordion_description.richText,
      image: item.featured_image.url,
      alt: item.featured_image.alt,
      image_fluid: item.featured_image.fluid,
      show: index ? false : true,
    }
  })

  const [state, setState] = useState(accordionData)
  const [src, setSrc] = useState(data.accordion[0].featured_image.url)
  const [alt, setAlt] = useState(data.accordion[0].featured_image.alt)

  const handleUpdate = (newState, newSrc, newAlt) => {
    setState(newState)
    setSrc(newSrc)
    setAlt(newAlt)
  }

  return (
    <div className="container flex flex-wrap mx-auto">
      <div className="flex-grow w-full">
        <div className="mx-auto w-full lg:w-5/12 text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold rfs:text-6xl mb-6">
              {data.title.text}
            </h2>
            <div className="w-12 mx-auto h-px bg-gold mb-8"></div>
          </Fade>
        </div>
      </div>
      <div className="flex-initial mx-auto w-full lg:w-6/12 text-center text-sm mb-10 lg:mb-20">
        <Fade distance="20px" bottom>
          <RichText
            render={data.description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </Fade>
      </div>
      <div className="flex-grow w-full flex flex-row items-center">
        <div className="flex-grow w-1/2 px-3">
          {state.map((item, index) => {
            return (
              <AccordionItem
                data={item}
                key={index}
                setItem={handleUpdate}
                arr={state}
              />
            )
          })}
        </div>
        <div className="flex-grow w-1/2 pl-12 lg:flex hidden">
          <Fade distance="20px" bottom>
            <CrossfadeImage src={src} alt={alt} />
          </Fade>
        </div>
      </div>
      <div className="mx-auto">
        <Fade distance="20px" bottom>
          <Link
            to={`/${props.type}/technically-superior/`}
            className="btn mt-16"
          >
            Read more
          </Link>
        </Fade>
      </div>
    </div>
  )
}

export default ProductAccordion
